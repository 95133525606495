export const teamData = [
  {
    name: "Kim Caicedo",
    id: "kim",
    role: "Director/Writer",
    text: [
      "Colombian Director Kimberly “Kim” Caicedo brings over 10 years of New York advertising experience to her Films. Her work has received accolades from the ARC Awards, Creativity International, and The Webby Awards, and received top honors from Fast Company’s Innovation By Design Awards.",
      "Her films have been screened at festivals across the world, including the Burbank International Film Festival, Sunscreen Film Festival, Gasparilla Film Festival, Dances with Films, and others. After writing and directing several shorts, in 2021 she filmed her first feature THE PINES, currently in festivals. She is currently a Directing fellow at the American Film Institute pursuing her master's in Film. Her practice focuses on visual communication, through her interest in allegorical rhetoric. Kim’s goal is to make work that is contemporary yet timeless.",
    ],
    image: "kim",
  },
  {
    name: "Kerisa Lin",
    id: "kerisa",
    role: "Producer",
    text: [
      "Shuchen 'Kerisa' Lin is a first-generation Chinese-American from Irvine, California. As a former nationally ranked tennis player, she branched out and pursued her passion in filmmaking. While earning her B.A. in Visual Arts (Media) and Business at the University of California, San Diego, she freelanced as a camera assistant on Lifetime TV, commercials, and music videos. She is now completing her MFA in Producing at the American Film Institute.",
      "This fall, Kerisa is working as a Development Intern at Ley Line Entertainment (THE GREEN KNIGHT, EVERYTHING EVERYWHERE ALL AT ONCE). Her latest producing credits include Kim Caicedo’s SANCOCHO short film and working as a Unit Production Manager on Indeed: Rising Voices Program’s THE 25TH FILIAL EXEMPLAR short film, which was in consideration for the 95th Academy Award for Best Live Action Short Film.",
      "",
    ],
    image: "kerisa",
  },
  {
    name: "Raquel Trujillo",
    id: "raquel",
    role: "Writer",
    text: [
      "Raised in a family of artists, Spanish screenwriter Raquel Trujillo’s life has always been linked to the arts. She has been working professionally as a theatre actress for more than 12 years, mainly in classical repertoire, starring in several productions from the Spanish Golden Age. She earned a bachelor's degree in Media Studies from CEU San Pablo University in Madrid, where she was also a Journalism major.",
      "Raquel also completed her film studies in London at St. Mary’s University and, in 2020, started training with Spanish screenwriter and showrunner, María López Castaño (Valeria, Netflix). Besides, her first project as a playwright will premiere this December 2023 in Spain, where she also participates as a creative producer.",
    ],
    image: "raquel",
  },
  {
    name: "Jessica Arzate",
    id: "jessica",
    role: "Cinematographer",
    text: [
      "Jessica Arzate is originally from Mexico City and lives in Los Angeles, California. She is currently attending the American Film Institute Conservatory. She has worked professionally in a wide range of crews on commercials, narrative films, and indie documentaries throughout Mexico, Spain, England, Japan, India, Israel, and the United States.",
      "Jessica's last narrative work, 'Romper el Pacto,' won best short film at Bordeaux's Independent International Film Festival. Also, it has been an official selection at the Guanajuato International Film Festival, Barcelona International Film Festival, Shorts México, and Mic Género. Jessica studied Philosophy at the National Autonomous University of Mexico (UNAM). She is a student member of the Society of Camera Operators. While not busy with AFI, she enjoys going to the beach and hiking around L.A.",
    ],
    image: "jessica",
  },
  {
    name: "Camila Pons",
    id: "camila",
    role: "Production Designer",
    text: [
      "Camila Pons Del Toro is a Mexican architect & designer for multiple visual mediums, specializing in production design for films, with work in architecture, styling, art direction, event design and Opera. She is currently going through a production design masters at The American Film Institute.",
      "She has studied at institutions such as ELISAVA in Barcelona, The University of Texas at Austin, and has designed projects in Mexico City, Madrid, and Vancouver. Her main focus is on strong and stylistic pieces ranging from period to sci-fi and always having a through line of magical realist elements, adding a touch of mysticism and magic to every project she collaborates on, Based in Los Angeles & Mexico",
    ],
    image: "camila",
  },
  {
    name: "Xueer Cai",
    id: "xu",
    role: "Editor",
    text: [
      "After graduating with a Journalism and Communication degree from the Chinese University of Hong Kong, Xueer Cai’s career ignited as a fashion magazine creative director. As her focus shifted to post-production, she wielded her aesthetics and creativity to edit commercials for renowned brands like Apple, BMW, ByteDance, Nike Women, Tencent News, and SK-II.",
      "In tandem with her commercial projects, Xueer's spare moments were dedicated to crafting award-winning mini-documentaries such as We Are All the Same and Zhu Gang - Life in Opera and Painting. In 2022, Xueer's passion for storytelling led her to the American Film Institute's editing program. Here, she ventured into narrative editing, sculpting captivating stories enriched by her delicate perspective, rhythmic control, and dramatic insight.",
      "",
    ],
    image: "xueer",
  },
];