import React, { useContext, useState, useRef, useEffect } from "react";
import "../styles/pages/team.scss";
import { teamData } from "../data/pages/team";
import { graphql } from "gatsby";
import gsap from "gsap";
import { assetArrayToObject } from "../lib/graphql-helpers";
import classNames from "classnames";
import ReactContext from "../context/reactContext";
import { useCheckScreenSize } from "../lib/useCheckScreenSize";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

const Team = ({ data, location }) => {
  const {
    allFile: { edges: allImages },
    teamCuts: { edges: cutsImages },
    teamTrad: { edges: tradImages },
  } = data;

  const teamImages = assetArrayToObject({
    useGatsbyImage: true,
    images: allImages,
  });

  const cutsImagesObject = assetArrayToObject({
    useGatsbyImage: true,
    images: cutsImages,
  });
  const tradImagesObject = assetArrayToObject({
    useGatsbyImage: true,
    images: tradImages,
  });

  const isTablet = useCheckScreenSize(960);
  const { currentTeamMember, setCurrentTeamMember, openDropdown, setOpenDropdown } = useContext(ReactContext);
  const dropdownRef = useRef(null);
  const profileRef = useRef(null);
  const bioRef = useRef(null);

  useEffect(() => {
    // If the dropdown is open, animate it
    if (openDropdown) {
      gsap.fromTo(
        dropdownRef.current,
        { height: 0 },
        { height: "auto", duration: 0.3 }
      );
    } else {
      // If it's closed, animate it back to 0
      gsap.to(dropdownRef.current, { height: 0, duration: 0.3 });
    }

    if (!isTablet) {
      gsap.fromTo(profileRef.current, {
        opacity: 0,
        y: 100,
      }, {
        opacity: 1,
        y: 0,
        duration: 0.3,
      })
    }
    if (!isTablet) {
      gsap.fromTo(bioRef.current, {
        opacity: 0,
        x: 100,
      }, {
        opacity: 1,
        x: 0,
        duration: 0.6,
      })
    }
  }, [openDropdown, currentTeamMember]);

  return (
    <div className="rw-team">
      <div className="rw-team__inner">
        <div className="rw-team__menu">
          {teamData.map((item) => {
            const menuItemClasses = classNames("rw-team__menu__item", {
              "rw-team__menu__item--mobile-active":
                currentTeamMember &&
                item.name === currentTeamMember.name &&
                openDropdown === item.id,
              "rw-team__menu__item--active":
                currentTeamMember && item.name === currentTeamMember.name,
            });
            return (
              <div
                onClick={() => {
                  setCurrentTeamMember(item);
                  if (openDropdown === item.id) {
                    setOpenDropdown(null);
                  } else {
                    setOpenDropdown(item.id);
                  }
                }}
                key={`${item.name}-menu-item`}
                className={menuItemClasses}
              >
                <h5>{item.role}</h5>
                <h2>{item.name}</h2>
                {openDropdown === item.id && currentTeamMember && (
                  <div ref={dropdownRef} className="rw-team__mobile-content">
                    <div className="rw-team__content__profile">
                      <GatsbyImage
                        image={teamImages[currentTeamMember.image]}
                        alt={`${currentTeamMember.name} image`}
                        placeholder="blurred"
                        className="rw-team__team-image"
                      />
                      <GatsbyImage
                        image={
                          tradImagesObject[`${currentTeamMember.image}Trad`]
                        }
                        alt={`${currentTeamMember.name} tradition`}
                        placeholder="blurred"
                        className="rw-team__team-image"
                      />
                    </div>
                    <div className="rw-team__bio">
                      <p>{currentTeamMember.text[0]}</p>
                      <GatsbyImage
                        image={
                          cutsImagesObject[`${currentTeamMember.image}cut`]
                        }
                        alt={`${currentTeamMember.name} image`}
                        placeholder="blurred"
                        className="rw-team__team-image"
                      />
                      <p>{currentTeamMember.text[1]}</p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {currentTeamMember && (
          <div className="rw-team__content">
            <div ref={profileRef} className="rw-team__content__profile">
              <GatsbyImage
                image={teamImages[currentTeamMember.image]}
                alt={`${currentTeamMember.name} image`}
                placeholder="blurred"
                className="rw-team__team-image"
              />
              <GatsbyImage
                image={tradImagesObject[`${currentTeamMember.image}Trad`]}
                alt={`${currentTeamMember.name} tradition`}
                placeholder="blurred"
                className="rw-team__team-image"
              />
            </div>
            <div ref={bioRef} className="rw-team__bio">
              <p>{currentTeamMember.text[0]}</p>
              <GatsbyImage
                image={cutsImagesObject[`${currentTeamMember.image}cut`]}
                alt={`${currentTeamMember.name} image`}
                placeholder="blurred"
                className="rw-team__team-image"
              />
              <p>{currentTeamMember.text[1]}</p>
            </div>
          </div>
        )}
        {!currentTeamMember && (
          <StaticImage
            src="../images/team/team.png"
            alt="Roller World Team"
            placeholder="blurred"
            className="rw-team__default-image"
          />
        )}
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "team" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          id
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 451
              height: 602
            )
          }
        }
      }
    }
    teamCuts: allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "team/cuts" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 409
              height: 309
            )
          }
        }
      }
    }
    teamTrad: allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "team/trad" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 451
              height: 210
            )
          }
        }
      }
    }
  }
`;

export default Team;
