const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );

export const assetArrayToObject = ({
  images,
  convertToCamel = true,
  useGatsbyImage = false,
}) => {
  const _images = images.edges ? images.edges : images;
  return _images.reduce((acc, { node }) => {
    const name = convertToCamel ? snakeToCamel(node.name) : node.name;
    acc[name] =
      useGatsbyImage && node.childImageSharp
        ? node.childImageSharp.gatsbyImageData
        : node.publicURL;
    return acc;
  }, {});
};

export const formatToGatsbyImage = ({ images, convertToCamel = true }) => {
  const _images = images.edges || images;

  if (!Array.isArray(_images)) {
    return {};
  }

  const formattedImages = _images.reduce((acc, { node }) => {
    const { name, publicURL, childImageSharp } = node;
    const formattedName = convertToCamel ? snakeToCamel(name) : name;
    acc[formattedName] =
      (childImageSharp && childImageSharp.fixed && childImageSharp.fixed.src) ||
      publicURL;
    return acc;
  }, {});

  return formattedImages;
};
